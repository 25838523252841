import type { QuizResponse } from "@/types/quiz";
import { QuestionTypeEnum } from "@/shared/types/quiz";

export const useSurveyStore = defineStore({
  id: 'survey',
  state: () => ({
    activeQuestion: null as number | null,
    questions: [] as QuizResponse[],
    customPagination: {
      history: [] as QuestionTypeEnum[]
    }
  }),
  getters: {
    currentQuestion: (state) => {
      return state.questions.find((item) => item.id === state.activeQuestion)
    },
    isEndingPage() {
      return (this.currentQuestion as Record<string, any>)?.type === QuestionTypeEnum.EndPage;
    }
  },
  actions: {
    addQuestion(question: QuizResponse) {
      if (!question) return;
      const hasSame = this.questions.find((item) => item.id === question.id)
      if (hasSame) {
        const index = this.questions.indexOf(hasSame);
        this.questions.splice(index, 1, question);
      } else {
        this.questions.push(question);
      }
    },
    setActiveQuestion(questionId: number) {
      this.activeQuestion = questionId;
    },
    nextPagination(questionType: QuestionTypeEnum) {
      if (questionType !== QuestionTypeEnum.EndPage) this.customPagination.history.push(questionType);
    },
    prevPagination() {
      if (this.customPagination.history.length === 0) return;

      this.customPagination.history.pop();
      const lastItem = this.customPagination.history[this.customPagination.history.length - 1];

      if (
        lastItem === QuestionTypeEnum.MidScreen &&
        this.customPagination.history.length > 0
      ) {
        this.prevPagination();
      }
    },
    resetPagination() {
      this.customPagination.history = [];
    }
  }
})
